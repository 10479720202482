@font-face {
    font-family: 'Crimson Text';
    src: url('../assets/fonts/CrimsonText-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Crimson Text';
    src: url('../assets/fonts/CrimsonText-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Crimson Text';
    src: url('../assets/fonts/CrimsonText-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Pretendard';
    src: url('../assets/fonts/Pretendard-Medium.otf') format('opentype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Pretendard';
    src: url('../assets/fonts/Pretendard-SemiBold.otf') format('opentype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Pretendard';
    src: url('../assets/fonts/Pretendard-Regular.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
}


@font-face {
    font-family: 'Teachers';
    src: url('../assets/fonts/Teachers-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Teachers';
    src: url('../assets/fonts/Teachers-BoldItalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Teachers';
    src: url('../assets/fonts/Teachers-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Teachers';
    src: url('../assets/fonts/Teachers-ExtraBoldItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Teachers';
    src: url('../assets/fonts/Teachers-Italic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Teachers';
    src: url('../assets/fonts/Teachers-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Teachers';
    src: url('../assets/fonts/Teachers-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Teachers';
    src: url('../assets/fonts/Teachers-Regular.ttf') format('truetype');
    font-weight: 350;
    font-style: normal;
}

@font-face {
    font-family: 'Teachers';
    src: url('../assets/fonts/Teachers-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Teachers';
    src: url('../assets/fonts/Teachers-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}