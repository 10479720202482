/* In your Tailwind CSS configuration or a separate CSS file */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    .translate-x-[-100%] {
        transform: translateX(-100%);
    }
    .translate-x-[100%] {
        transform: translateX(100%);
    }
}
