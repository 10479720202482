/* Partners.css */

/* General styles */
.img-left, .img-right {
    background-repeat: no-repeat;
    background-size: cover;
    /* background-position: center; */
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Specific styles for large screens */
@media (min-width: 768px) {
    .img-left, .img-right {
        height: 100vh;
    }
    .img-left {
        background-image: url('/public/assets/images/partners/partners.png');
    }
    .img-right {
        background-image: url('/public/assets/images/partners/Grow.png');
    }
}

/* Specific styles for small screens */
@media (max-width: 767px) {
    .img-left, .img-right {
        height: 50vh;
    }
    .img-left {
        background-image: url('/public/assets/images/partners/partners.png');
    }
    .img-right {
        background-image: url('/public/assets/images/partners/Grow.png');
    }
}

/* Button styles */
button {
    transition: transform 0.3s ease-in-out;
}

/* Hover effect */
button:hover {
    transform: scale(1.1);
}

/* Ensuring the image inside the button scales smoothly */
button img {
    display: block;
    width: 100%;
    height: auto;
}
